import { NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, effect, inject, Input, OnInit, WritableSignal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { GeoCoordinate, GetLocationResponse, IGetLocationResponse, LocationClient } from '../../services/api.services';
import { AuthService } from '../../services/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cs-hotspots',
  standalone: true,
  imports: [NgIf, NgFor, RouterLink],
  templateUrl: './hotspots.component.html',
  styleUrl: './hotspots.component.scss'
})
export class HotspotsComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  @Input() coordinates?: WritableSignal<GeoCoordinate | undefined>;

  constructor(private locationClient: LocationClient, private authService: AuthService) {
    effect(() => {
      const coord = this.coordinates?.();
      if (coord) {
        this.queryCoordinate(coord);
      }
    });
  }

  ngOnInit(): void {
    if (!this.coordinates) {
      this.authService.userProfile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
        const coord = user?.coordinate;
        if (coord && coord.lat && coord.lng) {
          this.queryCoordinate(coord);
        }
        else if (user?.locationCountryCode) {
          this.queryCountryCode(user.locationCountryCode);
        }
        else {
          this.queryByIP();
        }
      });
    }
  }

  queryCoordinate(coord: GeoCoordinate) {
    const lat = Math.round(coord.lat! * 10) / 10;
    const lng = Math.round(coord.lng! * 10) / 10;
    const distanceExpr = `distance(${lat},${lng})`;
    const search = `${distanceExpr} < 2000km`;
    const sort = `${distanceExpr} asc`;
    //altSort = `memberCount desc`;
    this.locationClient.list(0, 8, ``, search, sort).subscribe(locations => {
      const country = this.authService.userProfile$.value?.locationCountryCode;
      const filteredItems = (locations.items || []).filter(l => l.memberCount && (country == null || l.countryCode === country));
      this.setLocations(filteredItems);
    });
  }

  queryCountryCode(countryCode: string) {
    const filter = `"CountryCode" = '${countryCode}'`;
    const sort = `memberCount desc`;
    this.locationClient.list(0, 8, filter, ``, sort).subscribe(locations => {
      this.setLocations(locations.items);
    });
  }

  queryByIP() {
    const search = `distance() < 1000km`;
    const sort = `distance() asc`;
    this.locationClient.list(0, 8, ``, search, sort).subscribe(locations => {
      this.setLocations(locations.items);
    });
  }

  setLocations(locations: GetLocationResponse[] | undefined) {
    if (locations?.length) {
      this.locations = locations.filter(l => l.memberCount);
    }
    else {
      //TODO fallback
    }
  }

  locations: IGetLocationResponse[] = [];
}
