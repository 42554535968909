
<div *ngIf="locations.length" class="flex flex-wrap space-x-6 mt-4 justify-center text-white">
  <h2 class="font-display text-3xl pr-3">Hotspots nearby</h2>
  <div *ngFor="let location of locations" class="flex-1 text-center rounded-lg px-2 py-1 transition-colors border border-transparent hover:border-pink-300">
    <a [routerLink]="['/search']" [queryParams]="{slug: location.slug}" class="block font-medium underline text-nowrap">
      {{location.city || location.state || location.country}}
    </a>
    <span class="text-pink-300">{{location.memberCount}}</span>
  </div>
</div>
